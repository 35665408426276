<template>
    <div>
      <p v-if="loading">Loading...</p>
      <v-alert :value="errorMessage" type="error" class="my-0">
        Payment is failed
      </v-alert>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        loading: true,
        errorMessage: true,
        errorAlert: '',
      };
    },
    methods: {
    },
  };
  </script>